.topnav * {
  float: left;
  text-align: center;
}

.search-icon {
  padding-left: 20px;
  padding-top: 10px;
}

.search-box input {
  width: 300px;
  height: 24px;
}

.search-box {
  margin-left: 20px;
}

.search-box button {
  height: 20px;
}

.topnav-right {
  float: right;
  display: inline-flex;
}

button {
  background-color: #215bed;
  border: none;
  color: white;
  padding: 15px 15px;
  margin-left: 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
}

Button:hover {
  background-color: #083dc4;
}

.table-vehicle {
  margin-top: 100px;
  width: 100%;
}

.table-vehicle th,
.table-vehicle td {
  border-width: 1px;
  text-align: center;
  padding: 10px;
  font-size: 18px;
}

.add-form {
  background-color: whitesmoke;
  display: none;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid black;
  padding: 10px;
  width: 50%;
  height: 450px;
}

.add-form input,
.add-form select,
.add-form label,
.add-form button {
  font-size: 18px;
  margin: 10px;
}

.add-form p {
  font-size: 18px;
  margin: 10px;
  text-decoration: underline;
}

.btn-add {
  text-align: center;
}

.add-formClose {
  position: fixed;
  right: 5px;
  top: 5px;
}

.show {
  display: block;
}

.dropdown-content button {
  background-color: white;
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  width: 200px;
}

.dropdown-content button:hover {
  background-color: grey;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  z-index: 1;
}

#deleteSelect {
  font-size: larger;
}

/* Dummy Page */
.dummy p {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin: 0;
  padding: 10px;
  font-size: 50px;
}

img.dummy {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
}

.dummy p ul {
  font-size: 10px;
}

/* Dummy Page */

/* Resume */

.resume {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 20px;
  background-color: #f2f2f2;
}

.resume-header {
  text-align: center;
}

.resume-h1 {
  color: #333;
}

.resume-contact-info {
  margin-bottom: 20px;
}

.resume-contact-info p {
  margin: 0;
}

.resume-section {
  background-color: #fff;
  padding: 20px;
  margin-bottom: 20px;
  border: 1px solid #ddd;
}

.resume-section h2 {
  color: #333;
}

.resume-section p {
  margin: 0;
}

.resume-ul {
  list-style-type: none;
  padding: 0;
}

.resume-ul li {
  margin-bottom: 10px;
}

/* Resume */

/* for the expense tracker*/
.container {
  display: flex;
}

.dashboard {
  width: 50%;
  background-color: #dcf8c6;
}

.transactions {
  width: 50%;
  background-color: #f5e8c7;
}

.header {
  text-align: center;
  background-color: #ece5dd;
  padding-top: 10px;
  padding-bottom: 10px;
  margin: 0px;
  color: #075e54;
}

.yearly-header,
.monthly-header {
  display: flex;
}

.yearly-header p,
.monthly-header p {
  width: 50%;
  text-align: center;
  font-size: 1.6rem;
}

.yearly-header form,
.monthly-header form {
  width: 50%;
  align-content: center;
  align-items: center;
  align-self: center;
}

.yearly-header form input,
.monthly-header form input {
  text-align: center;
  font-size: 1.2rem;
  width: 180px;
}

.yearly-header form button,
.monthly-header form button {
  background-color: #ece5dd;
  text-align: center;
  font-size: 1.2rem;
}

.income-and-expense {
  display: flex;
}

.income,
.expense {
  width: 50%;
  text-align: center;
  font-size: 1.3rem;
}

.add-transaction form label,
.add-transaction form input,
.add-transaction form select {
  padding: 10px;
  margin-top: 10px;
  width: 20%;
}

.add-transaction form button {
  margin: 20px;
  margin-left: 150px;
  font-size: 1.6rem;
}

.filter-form {
  display: flex;
  padding-left: 20px;
}

.filter-inputs {
  width: 50%;
}

.filter-buttons {
  width: 50%;
}

.filter-input-column select {
  width: 133px;
}

.filter-input-column input {
  width: 125px;
}

.transactions-filter {
  margin-bottom: 20px;
}

.add-transaction-table tbody tr td input,
.add-transaction-table tbody tr td select,
.add-transaction-table tbody tr td label {
  width: 120px;
}

.transaction-list-table {
  font-size: 1.2rem;
}

.transaction-container {
  overflow-y: auto;
  height: 380px;
  padding: 10px;
}

.transaction-list-table thead td,
.transaction-list-table tr td {
  padding: 10px;
}

.desc {
  width: 250px;
}

/* for the expense traker */

/* Static S3 */

iframe {
  width: 98vw;
  height: 98vh;
}

/* Static S3 */

/* AWSGateway and Lambda */

.awsAPIandLambda button {
  width: 100%;
  font-size: larger;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}

.awsAPIandLambda img {
  width: 50%;
  padding-top: 10px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

/* AWSGateway and Lambda */

/* AWSGateway and S3 */

.awsS3Container {
  display: flex;
}

.awsS3Child {
  flex: 1;
  /* height: 100%; */
}

/* AWSGateway and S3 */


/* 3 Radio */

.radio-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
}

.radio-container * {
  scale: 1.2;
}

/* 3 Radio */


/* HomePage */

.Homepage-title {
  text-align: center;
  font-size: 40px;
  margin: 20px;
}

.Homepage-items {
  text-align: center;
  list-style-type: none;
  font-size: 20px;
}

/* Team Task Management */
.taskManagementTitle {
  text-align: center;
  width: 100%;
  /* display: block; */
}

.taskContainer {
  display: flex;
}

.left-column {
  width: 50%;
  display: flex;
  flex-direction: column;
}

.right-column {
  width: 50%;
}

.box {
  border: 1px solid black;
  padding: 20px;
}

.half-height {
  height: 50%;
}

.full-height {
  height: 100%;
}



div.TeamTaskManagementBlogContent h1,
div.TeamTaskManagementBlogContent h2,
div.TeamTaskManagementBlogContent h3,
div.TeamTaskManagementBlogContent h4 {
  color: #0056b3;
}

div.TeamTaskManagementBlogContent h1 {
  text-align: center;
  margin-bottom: 20px;
}

div.TeamTaskManagementBlogContent ul {
  padding-left: 20px;
}

div.TeamTaskManagementBlogContent ul li {
  margin-bottom: 10px;
}

.TeamTaskManagementBlogContent {
  margin: auto;
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  font-family: Arial, sans-serif;
  line-height: 1.6;
  margin: 20px;
  background-color: #f4f4f9;
  color: #333;
}

.TeamTaskManagementBlogContent img {
  width: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 800px;
}

.TeamTaskManagementBlogContent a {
  display: flex;
  justify-content: center;
  align-items: center;

}



/* Event Management */
.eventNavBar nav {
  top: 0;
  left: 0;
  width: 100%;
  padding: 20px;
  transition: all 0.4s ease;
  background-color: lightblue;
}

.eventNavBar nav.sticky {
  padding: 15px 20px;
  background: #4070f4;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
}

.eventNavBar nav .nav-content {
  height: 100%;
  max-width: 1200px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.eventNavBar nav .logo p {
  font-weight: 500;
  font-size: 35px;
  color: #4070f4;
}



.eventNavBar .nav-content .nav-links {
  display: flex;
}

.eventNavBar .nav-content .nav-links li {
  list-style: none;
  margin: 0 8px;
}

.eventNavBar .nav-links li a {
  text-decoration: none;
  color: #0E2431;
  font-size: 18px;
  font-weight: 500;
  padding: 10px 4px;
  transition: all 0.3s ease;
}

.eventNavBar .nav-links li a:hover {
  color: #4070f4;
}

.eventNavBar nav.sticky .nav-links li a {
  color: #fff;
  transition: all 0.4s ease;
}

.eventNavBar nav.sticky .nav-links li a:hover {
  color: #0E2431;
}

.eventNavBackground {
  height: 100px;
  width: 100vw;
  background-color: lightblue;
}

/* eventsHomePage */


.sliderImageDiv {
  position: relative;
  width: 100%;
  height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgb(29, 29, 29);
}


.eventsHomePage .slider_container {
  position: relative;
  width: 45%;
  height: 30rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
}

.eventsHomePage .slider {
  position: relative;
  width: 400%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  animation: 10s cubic-bezier(1, 0.95, 0.565, 1) sliding infinite;
}

.eventsHomePage .slide {
  position: relative;
  min-width: 100%;
  height: 100%;
}

.eventsHomePage .slide img,
.eventsHomePage .slide p {
  width: 100%;
  height: 100%;
}

.eventsHomePage .slide .caption {
  position: absolute;
  left: 0;
  bottom: 5%;
  font-size: 2rem;
  font-weight: 600;
  color: white;
  text-transform: capitalize;
  background: rgba(0, 0, 0, 0.348);
  backdrop-filter: blur(10px);
  padding: 1rem 5rem;
  border-radius: 0 2rem 2rem 0;
}

.eventsHomePage .slide.one {
  background: rgb(182, 19, 109);
}

.eventsHomePage .slide.two {
  background: rgb(255, 64, 64);
}

.eventsHomePage .slide.three {
  background: rgb(11, 173, 188);
}

.eventsHomePage .slide.four {
  background: rgb(11, 188, 14);
}

.eventsHomePage .slide.five {
  background: rgb(173, 11, 188);
}

@keyframes sliding {
  0% {
    transform: translateX(0%);
  }

  20% {
    transform: translateX(0%);
  }

  25% {
    transform: translateX(-100%);
  }

  45% {
    transform: translateX(-100%);
  }

  50% {
    transform: translateX(-200%);
  }

  70% {
    transform: translateX(-200%);
  }

  75% {
    transform: translateX(-300%);
  }

  95% {
    transform: translateX(-300%);
  }

  100% {
    transform: translateX(-400%);
  }
}

.description p {
  margin: 50px;
  text-align: center;
  font-size: 2rem;
  font-family: "DynaPuff";


}


/* Footer */

.myFooter {
  background-color: lightblue;
}

.myFooter p {
  margin: auto;
  align-self: center;
  justify-self: center;
}

.myFooter .nav-list li {
  padding-top: 5px;
  padding-bottom: 5px;
}

.myFooter .nav-list li a:hover:before {
  margin-left: 0;
  opacity: 1;
  visibility: visible;
}

.myFooter ul,
.myFooter ol {
  list-style: none;
  padding: 0;
  margin: 0;
}

.myFooter .social-inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 23px;
  font: 900 13px/1 "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  text-transform: uppercase;
  color: rgba(96, 0, 0, 0.5);
}

.myFooter .social-container .col {
  border: 1px solid rgb(107, 113, 134);
}


/* EventsPage */
.cards {
  margin-left: 75px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.eventsPage .card {
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 5px;
  margin: 1rem 0.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 30%;
  max-width: 30%;
}

.eventsPage button {
  background: #4CAF50;
  color: #fff;
  border: none;
  padding: 0.5rem;
  cursor: pointer;
  border-radius: 3px;
}

.eventsPage button:hover {
  background: #45a049;
}

.card img {
  width: 300px;
  height: 165px;
}

/* Events FeedBack Page */
.EventFeedbackContainer {
  max-width: 600px;
  margin: auto;
  background: white;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.EventFeedbackContainer .form {
  display: flex;
  flex-direction: column;
}

.EventFeedbackContainer .form-group {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.EventFeedbackContainer label {
  flex: 1;
  margin-right: 10px;
  font-weight: bold;
}

.EventFeedbackContainer input {
  flex: 2;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.EventFeedbackContainer button {
  padding: 10px 15px;
  background-color: #5cb85c;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

.EventFeedbackContainer button:hover {
  background-color: #4cae4c;
}


.newTaskContainer {
  max-width: 600px;
  margin: auto;
  background: white;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.newTaskContainer .form {
  display: flex;
  flex-direction: column;
}

.newTaskContainer .form-group {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.newTaskContainer label {
  flex: 1;
  margin-right: 10px;
  font-weight: bold;
}

.newTaskContainer input,
.newTaskContainer select {
  flex: 2;
  padding: 4px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.newTaskContainer button {
  padding: 10px 15px;
  background-color: #215bed;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

.newTaskContainer button:hover {
  background-color: #083dc4;
}

.buttonGroup {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0;
  margin-bottom: 0;
  align-content: center;
  text-align: center;
}

.buttonGroup button {
  margin: 5px;
}

/* Register Popup */
.popupBody {
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f0f0f0;
  font-family: Arial, sans-serif;
}

.btn-open-popup {
  padding: 12px 24px;
  font-size: 18px;
  background-color: green;
  color: #fff;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.btn-open-popup:hover {
  background-color: #4caf50;
}

.overlay-container {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.3s ease;
  z-index: 2;
}

.popup-box {
  background: #fff;
  padding: 24px;
  border-radius: 12px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
  width: 400px;
  text-align: center;
  opacity: 0;
  transform: scale(0.8);
  animation: fadeInUp 0.5s ease-out forwards;
}

.form-container {
  display: flex;
  flex-direction: column;
}

.form-label {
  margin-bottom: 10px;
  font-size: 16px;
  color: #444;
  text-align: left;
}

.form-input {
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  font-size: 16px;
  width: 100%;
  box-sizing: border-box;
}

.btn-submit,
.btn-close-popup {
  padding: 12px 24px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.btn-submit {
  background-color: lightblue;
  color: #fff;
}

.btn-close-popup {
  margin-top: 12px;
  background-color: #e74c3c;
  color: #fff;
}

.btn-submit:hover,
.btn-close-popup:hover {
  background-color: #4caf50;
}

/* Keyframes for fadeInUp animation */
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Animation for popup */
.overlay-container.show {
  display: flex;
  opacity: 1;
}


/* LoadingIcon */
.loadingOverlayContainer {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  opacity: 0;
  transition: opacity 0.3s ease;
  z-index: 2;
  text-align: center;
}

.spinner-loader {
  margin: 50vh;
}

/* AttendeePopup */
.attendees-overlay-container {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.3s ease;
  z-index: 2;
}

.attendees-overlay-container .popup-box {
  background: #fff;
  padding: 24px;
  border-radius: 12px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
  width: 900px;
  text-align: center;
  opacity: 0;
  transform: scale(0.8);
  animation: fadeInUp 0.5s ease-out forwards;
  overflow: scroll;
  margin: auto;
  margin-top: 70px;
  max-height: 80vh;
}

.attendees-table {
  margin: auto;

}


.attendees-table th,
.attendees-table td {
  border: 1px solid #ccc;
  text-align: center;
  padding: 10px;
}

/* FeedbackPopup */
.feedbacks-overlay-container {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.3s ease;
  z-index: 2;
}

.feedbacks-overlay-container .popup-box {
  background: #fff;
  padding: 24px;
  border-radius: 12px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
  width: 900px;
  text-align: center;
  opacity: 0;
  transform: scale(0.8);
  animation: fadeInUp 0.5s ease-out forwards;
  overflow: scroll;
  margin: auto;
  margin-top: 70px;
  max-height: 80vh;
}

.feedbacks-table {
  margin: auto;

}


.feedbacks-table th,
.feedbacks-table td {
  border: 1px solid #ccc;
  text-align: center;
  padding: 10px;
}

/* EventManagementBlog */
.EventManagementBlogContent h1,
.EventManagementBlogContent h2,
.EventManagementBlogContent h3,
.EventManagementBlogContent h4 {
  color: #0056b3;
}

.EventManagementBlogContent h4 {
  margin-top: 30px;
}

.EventManagementBlogContent p {
  margin-bottom: 10px;
}

.EventManagementBlogContent ul {
  margin: 10px 0;
  padding-left: 20px;
}

.EventManagementBlogContent li {
  margin-bottom: 8px;
}

.EventManagementBlogContent strong {
  color: #0056b3;
}

.EventManagementBlogContent .highlight {
  background-color: #f9f9f9;
  padding: 10px;
  border-left: 4px solid #0056b3;
  margin: 20px 0;
}

/* BlogHostingAppcontainer */


.BlogHostingAppcontainer {
    width: 80%;
    margin: auto;
    background: #fff;
    padding: 20px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
}

.BlogHostingAppcontainer .main-header {
    text-align: center;
    background: white;
    color: #0073e6;
    padding: 20px 10px;
    border-radius: 8px 8px 0 0;
}

.BlogHostingAppcontainer .main-header h1 {
    margin: 0;
    font-size: 28px;
}

.BlogHostingAppcontainer .main-header p {
    margin: 5px 0 0;
    font-size: 14px;
}

.BlogHostingAppcontainer section {
    margin: 20px 0;
    padding-bottom: 10px;
}

.BlogHostingAppcontainer section h1 {
    font-size: 30px;
    color: #0073e6;
    border-bottom: 2px solid #0073e6;
    padding-bottom: 5px;
    margin-bottom: 10px;
}

.BlogHostingAppcontainer .subsection {
    margin: 10px 0;
}

.BlogHostingAppcontainer .subsection h3 {
    font-size: 18px;
    color: #005bb5;
    margin-bottom: 5px;
}

.BlogHostingAppcontainer p {
    margin: 10px 0;
}

.BlogHostingAppcontainer ul {
    margin: 10px 0 10px 20px;
    list-style-type: disc;
}

.BlogHostingAppcontainer ul li {
    margin-bottom: 8px;
}

.BlogHostingAppcontainer strong {
    color: #005bb5;
}